import React from "react";
import CountBox from "../widgets/countBox";
import documentsImg from '../../assets/img/widgets/documents.svg';
import applicantsImg from '../../assets/img/widgets/applicants.svg';
import dispatchedJobImg from '../../assets/img/widgets/dispatched-job.svg';
import projectCompletedImg from '../../assets/img/widgets/project-completed.svg';
import { AnimationOnScroll } from "react-animation-on-scroll";

function SectionCount() {
  return (
    <AnimationOnScroll animateIn="animate__fadeInUp custom-row">
      <div className="row p-md-5">
        <div className="col-lg-3 col-sm-6 col-12">
          <CountBox text="Documents generated" count="1000" src={documentsImg} />
        </div>
        <div className="col-lg-3 col-sm-6 col-12">
          <CountBox text="Applications Processed" count="30500" src={applicantsImg} />
        </div>
        <div className="col-lg-3 col-sm-6 col-12">
          <CountBox text="Dispatched Jobs" count="1700" src={dispatchedJobImg} />
        </div>
        <div className="col-lg-3 col-sm-6 col-12">
          <CountBox text="Project Completed" count="5000" src={projectCompletedImg} />
        </div>
      </div>
    </AnimationOnScroll>
  )
}

export default SectionCount;