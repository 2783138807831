import React from "react";
import './css/numberBox.css';
import CountUp from 'react-countup';

function NumberBox(props) {
    return (
        <div className="number-box">
            {/* <span className="number-box-icon"><i className="far fa-envelope"></i></span> */}
            <span className="number-box-icon"><img src={props.src ? props.src : ''} atr="no" /></span>
            <div className="number-box-content">
                {/* <span className="number-box-number text-black">{props.count ? props.count + '+' : 0}</span> */}
                <h3 className="number-box-text">{props.text ? props.text : ''}</h3>
                <span className="number-box-number text-black"><CountUp duration={6.75} end={props.count ? Math.abs(props.count) : 0} />
                    <span className={`badge ${props.count > 0 ? 'number-up' : 'number-down'} ml-2`}><i className={`fa ${props.count > 0 ? 'fa-arrow-up' : 'fa-arrow-down'} `}></i> {props.percentage ? props.percentage : 0}%</span>
                </span>

                <h3 className="number-box-text">7 Days</h3>
            </div>
        </div>
    )
}

export default NumberBox;