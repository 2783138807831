import React from "react";
import './css/cardTop.css';

function CardTop(props) {

  return (
    <div className="card-top" style={{ borderColor: `${props.color ? props.color : 'black'}` }}>
      <div className="card-top-content">
        <h2 className="card-top-title text-pink" style={{ color: `${props.color ? props.color : 'black'}` }}>{props.title ? props.title : "set title"}</h2>
        <h3 className="card-top-description">{props.description ? props.description : "set description"}</h3>
      </div>
    </div >
  )
}

export default CardTop;